const LOCAL_STORAGE_KEY = '__hudlUniformDesignCodePreference__';

export const setDesignCodePreference = preference => {
  if (!window) return;
  window.localStorage.setItem(LOCAL_STORAGE_KEY, preference);
};

export const getDesignCodePreference = () => {
  if (typeof window === 'undefined') return 'design';

  const currentPreference = window.localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!currentPreference) {
    setDesignCodePreference('design');
  }

  return currentPreference || 'design';
};
